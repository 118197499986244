import React, { useState, useEffect } from 'react';
import './BackgroundVideo.css'; // Ensure this CSS file exists and is correctly linked

const BackgroundVideo = () => {
  // State to track if the device is mobile
  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth <= 768; // Define mobile as width <= 768px
    }
    return false; // Default to desktop if window is undefined (e.g., during SSR)
  });

  useEffect(() => {
    // Function to check if the screen is mobile-sized
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile as width <= 768px
    };

    // Initial check
    checkMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkMobile);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Choose the video source based on the screen size
  const videoSrc = isMobile
    ? '/mobile-background-video.mp4' // Path to your mobile video
    : '/wallyworld-background.mp4';      // Path to your desktop video

  return (
    <video autoPlay loop muted playsInline className="background-video">
      <source src={videoSrc} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default BackgroundVideo;
